import axiosInstance from '../../assets/utils/api';

const sendMsg = async (fullName, email, phone, message) => {
    try {
        const response = await axiosInstance.post(`/api/contact-us`, {
            first_name: fullName, email: email, phone: phone, message: message
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default sendMsg;