import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./EventDetail.css";
import Calender from "../../../assets/images/frontend/calendar.svg";
import Location from "../../../assets/images/frontend/orange-map-pin.svg";
import HomeBG from "../../../assets/images/frontend/event-banner.jpg";
import EventImage from "../../../assets/images/frontend/event-detail-img.png";
import eventsDetails from "../../Apis/eventsDetailsApi";
import trackingClick from "../../Apis/trackingClickApi";
import getBanner from "../../Apis/bannerApi";
import CryptoJS from "crypto-js";
const EventDetails = () => {
  const [eventDetail, setEventDetail] = useState([]);
  const [eventID, setEventID] = useState();
  const [error, setError] = useState(null);
  const [date, setDate] = useState("");
  const [endPoint, setEndPoint] = useState("");
  const [imageBG, setImageBG] = useState(HomeBG);
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const location = useLocation();
  const lastSegment = location.pathname.split("/").filter(Boolean).pop();
  const eventName = lastSegment.replaceAll("-", " ");
  const key = "PG2024";
  const url = "https://pythiangames.org";
  const [bannerImage, setBannerImage] = useState('')

  const handleBanner = async () => {
    let path = location.pathname.replace(/^\//, '');
    try {
      const response = await getBanner('1st-National-Cultural-Pythian-Games-2024');
      const url = "https://pythiangames.org" + response[0].imgurl;
      setImageBG(url);
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    handleBanner();
    const fetchEventDetail = async () => {
      try {
        const response = await eventsDetails(eventName);
        const selectedEvent = response[0];

        if (selectedEvent) {
          setEventDetail(selectedEvent);

          const dateString = selectedEvent.eventstartdate;
          const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          const formattedDate = new Date(dateString).toLocaleDateString(
            "en-US",
            options
          );
          setDate(formattedDate);

          setEndPoint(
            encryptStr(
              `id=${selectedEvent.eventid}&tid=${getSessionStorage(
                "ssonTokn"
              )}`,
              key
            )
          );
        } else {
          setError("Event not found.");
        }
      } catch (error) {
        console.error("Error fetching event details:", error);
        setError("Failed to load event details.");
      }
    };
    fetchEventDetail();
  }, []);

  useEffect(() => {
    const calculateCountdown = () => {
      if (!eventDetail) return;
      const targetDate = new Date(eventDetail.eventstartdate).getTime();
      const now = new Date().getTime();
      const difference = targetDate - now;
      if (difference < 0) {
        setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }
      setCountdown({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      });
    };
    const timer = setInterval(calculateCountdown, 1000);
    return () => clearInterval(timer);
  }, [eventDetail]);
  function scrambleKey(v_strKey) {
    let scrambledKey = "";
    for (let i = v_strKey.length - 1; i >= 0; i--) {
      scrambledKey += v_strKey.charAt(i);
    }
    return scrambledKey;
  }
  function encryptStr(input, key) {
    key = scrambleKey(key);
    const md5Key = CryptoJS.MD5(key).toString();
    const keyWordArray = CryptoJS.enc.Hex.parse(md5Key);
    const encrypted = CryptoJS.TripleDES.encrypt(input, keyWordArray, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }
  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${daySuffix(day)} ${month} ${year}`;
  }
  const getSessionStorage = (key) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  };

  return (
    <>
      <div className="inner-banner large">
        <figure>
          <img
            key={"banner"}
            src={imageBG}
            alt="Event Banner"
          />
        </figure>
        <div className="banner-content">
          <div className="inner-banner-txt">
            <h1>{eventDetail?.eventname}</h1>
            <div className="meta-data mt-2">
              <ul>
                <li>
                  <img src={Calender} alt="Calendar" />
                  <span>{date}</span>
                </li>
                <li>
                  <img src={Location} alt="Location" />
                  <span>{eventDetail?.address}</span>
                </li>
              </ul>
            </div>
            <a
              href={`${eventDetail?.buttonurl}${endPoint}`}
              className="action-button mt-3 mt-md-5"
            >
              <span></span>
              <i>{eventDetail?.buttontext}</i>
            </a>
          </div>
        </div>
      </div>

      <section className="about section" style={{ animationDelay: "200ms" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6" style={{ animationDelay: "2s" }}>
              <h1 className="mb-4 lh-base titleFont title">
                <span
                  onClick={() =>
                    window.location.href = `${eventDetail?.buttonurl}${endPoint}`
                  }
                  style={{ color: "#0AA555", cursor: "pointer" }}
                >
                  Join Us:
                </span>{" "}
                <br />
                {eventDetail?.eventname}
              </h1>
              <p>
                We are excited to announce the {eventDetail?.eventname}, a
                historic event celebrating culture and tradition like never
                before!
              </p>
              <p>
                This is the first-ever cultural games event in the world, and we
                are proud to host it at:
              </p>
              <div className="location-detail mb-3">
                <table>
                  <tr>
                    <td className="fw-bold">Location:</td>
                    <td className="fw-bold">
                      {eventDetail?.address}, {eventDetail?.location}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Event Start Date:</td>
                    <td className="fw-bold">
                      {formatDate(eventDetail?.eventstartdate)}
                    </td>
                  </tr>

                  <tr>
                    <td className="fw-bold">Event Close Date:</td>
                    <td className="fw-bold">
                      {formatDate(eventDetail?.eventenddate)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Registration Open:</td>
                    <td className="fw-bold">
                      {formatDate(eventDetail?.regstartdate)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold">Registration Closes:</td>
                    <td className="fw-bold">
                      {formatDate(eventDetail?.regenddate)}
                    </td>
                  </tr>
                </table>
                <p className="mb-0">
                  <b>Calling All Artists and Players!</b>
                </p>
                <a
                  href={`${eventDetail?.buttonurl}${endPoint}`}
                  className="action-button mt-3 mt-md-5"
                >
                  <span></span>
                  <i>{eventDetail.buttontext}</i>
                </a>
                <p className="mb-0">
                  Whether you are an artist or a player, you can register in
                  your field of participation if it is mentioned in our list.
                  Don’t miss this chance to showcase your talent on a national
                  platform.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="about-img">
                <div className="about-card2">
                  <img className="w-100" src={EventImage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

      <section className="counter-section">
        <div className="container">
          <div className="counter-wrap">
            <h2>Count Every Second Until the Games</h2>
            <div className="live-clock">
              <ul>
                <li>
                  <b>{countdown.days}</b>
                  <span>days</span>
                </li>
                <li>
                  <b>{countdown.hours}</b>
                  <span>hours</span>
                </li>
                <li>
                  <b>{countdown.minutes}</b>
                  <span>minutes</span>
                </li>
                <li>
                  <b>{countdown.seconds}</b>
                  <span>seconds</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <h1 className="mb-4 lh-base titleFont title text-center">
            Event Related Categories
          </h1>
          <div className="row">
            {eventDetail?.relatedcategories?.map((item, index) => (
              <div key={index} className="eventCategoryList">{item.category}</div>
            ))}

            <div style={{ cursor: "pointer" }} className="eventCategoryList">
              Create Your Own event
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventDetails;
