import React, {useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import InnerBanner from '../../Components/InnerBanner/InnerBanner';

const PartnerDetails = () => {
    const {id} = useParams(); // Get the id from the URL parameters
    const [data, setData] = useState({}); // Start with an empty object
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const state = location.state;

    // useEffect(() => {
    //     const fetchDetails = async () => {
    //         try {
    //             const response = await axios.get(`https://reg.pythiangames.org/api/partners/${id}`);
    //             // Assuming the data is under response.data.partners
    //             if (response.data && response.data.partners) {
    //                 setData(response.data.partners); // Set the partners data
    //             } else {
    //                 setError("No details found for this entry.");
    //             }
    //         } catch (error) {
    //             console.error("Error fetching details:", error);
    //             setError("Failed to fetch details. Please try again later.");
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };
    //
    //     fetchDetails();
    // }, [id]);

    // Loading state
    // if (isLoading) {
    //     return <p>Loading...</p>;
    // }
    //
    // // Error handling
    // if (error) {
    //     return (
    //         <div className="error-message text-center">
    //             <h2>Error</h2>
    //             <p>{error}</p>
    //         </div>
    //     );
    // }

    // Check if data is empty
    // if (!data || Object.keys(data).length === 0) {
    //     return (
    //         <div className="no-data-message text-center">
    //             <h2>No Details Available</h2>
    //             <p>We couldn't find any details for this entry.</p>
    //         </div>
    //     );
    // }

    // Construct the image URL
    const imageUrl = `https://reg.pythiangames.org/${state.src?.replace(/\\/g, "/")}`;

    // Render the details here
    return (
        <>
            <InnerBanner pageName={"Partner Information"}/>
            <section className="cultural section">
                <div className="container">
                    <div className="row g-4 mb-4">
                        <div className="col-md-4 col-lg-3 col-12">
                            <div className="">
                                <img src={imageUrl} className="w-100" alt={state.name || 'Partner Image'}/>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                            <div className="">
                                <div className="">
                                    <h2 className="mt-12">{state.name}</h2>
                                    <p><b>{state.profile}</b></p>
                                    <p><b>Country: {state.country}</b></p>
                                    <p>{state.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PartnerDetails;
