import React, { useEffect, useState } from "react";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import "./OurCounsil.css";
import { useNavigate } from "react-router-dom";
import ourCaouncil from '../../../data/ourCouncil.json';

const OurCouncil = () => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch data from the API
        const fetchData = () => {
            try {
                // const response = await axios.get(
                //     "https://reg.pythiangames.org/api/partners/0"
                // );
                // // Assuming the data is in response.data.partners
                setData(ourCaouncil.partners);
                console.log(ourCaouncil)
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const Card = ({ id, src, name, title, description }) => {
        const navigate = useNavigate();

        const handleTitleClick = () => {
            navigate(`details`, {
                state: {
                    id: id,
                    src: src,
                    name: name,
                    title: title,
                    description: description
                }
            });
        };

        const imageUrl = `https://pythiangames.org/${src.replace(/\\/g, "/")}`;
        return (<div className="col-md-4 col-lg-3 col-12">
            <div className="ambass-card">
                <img src={imageUrl} alt={`${name}'s photo`} />
                <div
                    className="ambass-text"
                    onClick={handleTitleClick}
                    style={{ cursor: "pointer" }}
                >
                    <p className="mt-12 text-center">
                        {name}
                        <span>{title}</span>
                    </p>
                </div>
            </div>
        </div>);
    };

    // Helper function to render each section
    const renderSection = (sectionData, title) => {
        if (!sectionData || sectionData.length === 0) {
            return null;
        }

        return (<>
            <h2 className="text-center mb-5 mt-5 titleFont title">{title}</h2>
            <div className="row g-4 mb-4">
                {sectionData.map(({ id, src, name, profile, designation }) => (<Card
                    key={id}
                    id={id} // Pass the id to the Card
                    src={src}
                    name={name}
                    title={profile}
                    description={designation}
                />))}
            </div>
        </>);
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (<>
        <InnerBanner pageName="Our Council" />
        <section className="cultural section">
            <div className="container">
                {renderSection(data.GlobalAmbassadors, "Global Cultural Ambassadors")}
                {renderSection(data.ExecutiveBoard, "Executive Board")}
                {renderSection(data.Chairperson, "Chairperson - Committees / Commissions")}
                {renderSection(data.Advisor, "Advisors")}
                {renderSection(data.team, "Team Members")}
            </div>
        </section>
    </>);
};

export default OurCouncil;
