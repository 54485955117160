import { Link, useLocation } from "react-router-dom";
import getBanner from "../../Apis/bannerApi";
import { useEffect, useState } from "react";
const InnerBanner = ({ pageName, altText }) => {
    const path = 'https://pythiangames.org';
    const location = useLocation();
    const [bannerData, setBannerData] = useState({})

    const handleBanner = async () => {
        let path = location.pathname.replace(/^\//, '');
        try {
            const response = await getBanner(path);
            setBannerData(response[0]);
            console.log('BannerData: ', response[0]);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        handleBanner();
    }, [location])

    return (<>
        <div className="inner-banner">
            <figure>
                <img src={`${path}/${bannerData.imgurl}`} alt={bannerData.alttext} />
            </figure>
            <div className="breadcrumb-wrap">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link routerLink="/home">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{pageName}</li>
                    </ol>
                </nav>
                <h2>{altText || pageName}</h2>
            </div>
        </div>
    </>)
}
export default InnerBanner