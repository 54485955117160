import './Banner.css'
import {useEffect, useState} from "react";
import getBanner from "../../Apis/bannerApi";
import {useLocation} from "react-router-dom";

const Banner = () => {

    const location = useLocation();
    const [bannerData, setBannerData] = useState({})

    const handleBanner = async () => {
        let path = location.pathname.replace(/^\//, '');
        try {
            const response = await getBanner(path === '' || path === null ? 'home' : path);
            response.forEach((item) => {
                if (item.priority == 1) {
                    setBannerData(item);
                    return;
                }
            })
            console.log('BannerData: ', response);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        handleBanner();
    }, [location])

    return (
        <div className="banner">
            <img className='home-banner-image' src={`${process.env.REACT_APP_STORAGE_URL}/${bannerData.imgurl}`}
                 alt={bannerData.alttext}/>
            <div className="home-banner-content">
                <h1>{bannerData.headingtext}</h1>
                <a href={bannerData.buttonurl}
                   className="action-button mt-2 mt-md-4"><span></span><i>{bannerData.buttontext}</i></a>
            </div>
        </div>
    )
}
export default Banner