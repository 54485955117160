// src/components/Donation/Donation.js

import React, {useState} from 'react';
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import {Alert, Button, Form, Modal} from 'react-bootstrap';
import QRcode from '../../../assets/images/frontend/qr-code.png';
import pdf1 from '../../../assets/images/doc/Approval-Letter-for-form-CSR1.pdf';
import pdf2 from '../../../assets/images/doc/Delphic-India-Trust-12A-Registration.pdf';
import axios from 'axios';
import './Donation.css'; // Your custom CSS

const Donation = () => {
    // Form state
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        amount: '',
        phone: '',
        remark: '',
    });

    // Validation state
    const [errors, setErrors] = useState({});

    // Modal visibility state
    const [showModal, setShowModal] = useState(false);

    // Success message state
    const [showSuccess, setShowSuccess] = useState(false);

    // Error message state
    const [showError, setShowError] = useState('');

    // Handle modal open
    const handleShow = () => setShowModal(true);

    // Handle modal close
    const handleClose = () => {
        setShowModal(false);
        setFormData({
            name: '',
            email: '',
            amount: '',
            phone: '',
            remark: '',
        });
        setErrors({});
        setShowError('');
    };

    // Handle input changes
    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Clear errors as user types
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    // Form validation function
    const validateForm = () => {
        const {name, email, amount, phone, remark} = formData;
        const newErrors = {};

        if (!name.trim()) newErrors.name = 'Name is required';
        if (!email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!amount) {
            newErrors.amount = 'Amount is required';
        } else if (amount < 1) {
            newErrors.amount = 'Amount must be at least 1';
        }
        if (!phone.trim()) {
            newErrors.phone = 'Phone number is required';
        } else if (!/^\d{10,}$/.test(phone)) {
            newErrors.phone = 'Phone number must be at least 10 digits';
        }
        if (!remark.trim()) newErrors.remark = 'Remark is required';

        return newErrors;
    };

    // Function to load Razorpay script dynamically
    const loadRazorpay = () => {
        return new Promise((resolve) => {
            if (window.Razorpay) {
                resolve(true);
            } else {
                const script = document.createElement('script');
                script.src = 'https://checkout.razorpay.com/v1/checkout.js';
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            }
        });
    };

    // PaymentService encapsulated within the component
    const PaymentService = {
        payNow: async (amount, name, email, phone, remark, token) => {
            const res = await loadRazorpay();

            if (!res) {
                setShowError('Razorpay SDK failed to load. Please check your internet connection.');
                return;
            }

            const currentDate = new Date();
            const paymentDate = currentDate.toISOString();

            const options = {
                description: 'Thank you for your donation!',
                currency: 'INR',
                amount: amount * 100, // Amount in paise
                name: 'Your Organization Name',
                key: 'rzp_live_EtyIA627A0V6mO', // Replace with your Razorpay API key
                image: '', // You can add your logo URL here
                prefill: {
                    name: name,
                    email: email,
                    contact: phone,
                },
                theme: {
                    color: '#6466e3',
                },
                modal: {
                    ondismiss: () => {
                        console.log('Payment dismissed');
                    },
                },
                handler: async (response) => {
                    await PaymentService.handlePaymentSuccess(response, name, email, amount, phone, remark, paymentDate, token);
                },
            };

            const rzp = new window.Razorpay(options);

            rzp.on('payment.failed', async (response) => {
                await PaymentService.handlePaymentFailure(response, name, email, amount, phone, remark, paymentDate, token);
            });

            rzp.open();
        },

        handlePaymentSuccess: async (response, name, email, amount, phone, remark, paymentDate, token) => {
            const paymentDetails = {
                payment_data: {
                    name: name,
                    email: email,
                    amount: amount,
                    phone: phone,
                    paymentdate: paymentDate,
                    paymentId: response.razorpay_payment_id,
                    status: 'successful',
                },
                remark: remark,
            };

            try {
                const res = await axios.post('/api/donations', paymentDetails, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                console.log('Payment successful:', res.data);
                setShowSuccess(true);
                handleClose();
            } catch (error) {
                console.error('Error processing payment:', error);
                setShowError('Payment was successful, but there was an issue processing your donation. Please contact support.');
            }
        },

        handlePaymentFailure: async (response, name, email, amount, phone, remark, paymentDate, token) => {
            const paymentDetails = {
                payment_data: {
                    name: name,
                    email: email,
                    amount: amount,
                    phone: phone,
                    paymentdate: paymentDate,
                    paymentId: response.error.metadata.payment_id,
                    status: 'failed',
                },
                remark: remark,
            };

            try {
                const res = await axios.post('/api/donations', paymentDetails, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                console.log('Payment failure logged:', res.data);
            } catch (error) {
                console.error('Error logging failed payment:', error);
            }

            setShowError('Payment failed. Please try again or contact support.');
        },
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        setShowModal(false)
        setFormData([])
        // if (Object.keys(formErrors).length === 0) {
        //   // No errors, proceed with payment
        //   const token = localStorage.getItem('token'); // Assuming you store token in localStorage
        //   if (!token) {
        //     setShowError('You need to be logged in to make a donation.');
        //     return;
        //   }

        //   try {
        //     await PaymentService.payNow(
        //       parseFloat(formData.amount),
        //       formData.name,
        //       formData.email,
        //       formData.phone,
        //       formData.remark,
        //       token
        //     );
        //   } catch (error) {
        //     console.error('Error initiating payment:', error);
        //     setShowError('An unexpected error occurred. Please try again later.');
        //   }
        // } else {
        //   // Set errors to display
        //   setErrors(formErrors);
        // }
    };

    return (
        <>
            <InnerBanner pageName="Donation"/>
            <section className="post section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 mx-auto">
                            <h2 className="text-center lh-base titleFont title">Donate Now</h2>
                            <p className="mb-5 text-center">
                                Alone we can do so little, together we can do so much
                            </p>
                            <div className="mt-4 text-center qr">
                                <img src={QRcode} alt="QR Code" className="scan"/>
                            </div>
                            <div className="details mt-4 text-start">
                                <dl className="row">
                                    <dt className="col-sm-5 col-12">Bank Name</dt>
                                    <dd className="col-sm-7 col-12">UCO Bank</dd>

                                    <dt className="col-sm-5 col-12">Branch Name</dt>
                                    <dd className="col-sm-7 col-12">South Patel Nagar, New Delhi</dd>

                                    <dt className="col-sm-5 col-12">Account Number</dt>
                                    <dd className="col-sm-7 col-12">10210210003114</dd>

                                    <dt className="col-sm-5 col-12">Account Type</dt>
                                    <dd className="col-sm-7 col-12">Current Account</dd>

                                    <dt className="col-sm-5 col-12">IFSC Code</dt>
                                    <dd className="col-sm-7 col-12">UCBA0001021</dd>
                                </dl>
                            </div>
                            <button onClick={handleShow} className="w-100 register mt-4">
                                Donate Now
                            </button>
                            <div className="links">
                                <ul className="d-flex">
                                    <li className="mb-2">
                                        <a
                                            href={pdf1}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-link"
                                        >
                                            Eligible for CSR
                                        </a>
                                    </li>
                                    <li className="mb-2">
                                        <a
                                            href={pdf2}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-link"
                                        >
                                            Registered under 12A and 80G
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Success Alert */}
            {showSuccess && (
                <Alert
                    variant="success"
                    onClose={() => setShowSuccess(false)}
                    dismissible
                    className="m-3"
                >
                    Thank you for your donation!
                </Alert>
            )}

            {/* Error Alert */}
            {showError && (
                <Alert
                    variant="danger"
                    onClose={() => setShowError('')}
                    dismissible
                    className="m-3"
                >
                    {showError}
                </Alert>
            )}

            {/* React Bootstrap Modal */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Donate Now</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit} noValidate>
                        {/* Name Field */}
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your full name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Email Field */}
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Amount Field */}
                        <Form.Group className="mb-3" controlId="formAmount">
                            <Form.Label>Donation Amount (INR)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter amount"
                                name="amount"
                                value={formData.amount}
                                onChange={handleChange}
                                min="1"
                                isInvalid={!!errors.amount}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.amount}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Phone Field */}
                        <Form.Group className="mb-3" controlId="formPhone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Enter your phone number"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                isInvalid={!!errors.phone}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.phone}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Remark Field */}
                        <Form.Group className="mb-3" controlId="formRemark">
                            <Form.Label>Remark</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter any remarks or messages"
                                name="remark"
                                value={formData.remark}
                                onChange={handleChange}
                                isInvalid={!!errors.remark}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.remark}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Submit Button */}
                        <Button variant="success" type="submit" className="w-100">
                            Submit Donation
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Donation;
