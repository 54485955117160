import '@fontsource/open-sans'
import './Header.css'
import '../../../assets/font/Font.css'
import logo from '../../../assets/images/logos/header-logo.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import {FaEarthAmericas, FaFacebookF, FaInstagram, FaLinkedin, FaXTwitter, FaYoutube} from "react-icons/fa6";
import {Link, useNavigate} from "react-router-dom";
import {Box, Drawer, useMediaQuery, useTheme} from "@mui/material";
import {Accordion} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FaAngleDown} from "react-icons/fa";
import {LuMenu} from "react-icons/lu";
import {CgProfile} from "react-icons/cg";
import {signupUrl} from '../../../assets/utils/signup';
import {loginUrl} from '../../../assets/utils/loginUrl';

const Header = ({isScrolled}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [open, setOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768); // Adjust screen size flag on window resize
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    const navigate = useNavigate();
    const loadGoogleTranslate = () => {
        if (!document.querySelector('#google-translate-script')) {
            const script = document.createElement('script');
            script.id = 'google-translate-script';
            script.type = 'text/javascript';
            script.src = `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`;

            script.onerror = () => {
                console.error('Failed to load Google Translate script');
            };
            document.head.appendChild(script);
        }
        window.googleTranslateElementInit = googleTranslateElementInit;
    };
    const googleTranslateElementInit = () => {
        if (window.google && window.google.translate) {
            document.getElementById('google_translate_element').innerHTML = '';
            if (isSmallScreen) {
                new window.google.translate.TranslateElement({
                    pageLanguage: 'fr', layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
                }, 'google_translate_element');
            } else {
                new window.google.translate.TranslateElement({
                    pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
                }, 'google_translate_element');
            }
        } else {
            console.error('Google Translate API is not available.');
        }
    };
    useEffect(() => {
        loadGoogleTranslate();
    }, []);


    const DrawerList = (<Box sx={{width: '350px', height: '100%'}} role="presentation" className='mobile-menu'>
        <Link to="/" className='mobile-item' onClick={toggleDrawer(false)}>Home</Link>
        <Accordion className='d-flex flex-column'>
            <Accordion.Item eventKey="0">
                <Accordion.Header>About Us</Accordion.Header>
                <Accordion.Body className='d-flex flex-column'>
                    <Link to="/founders-message" className='mobile-item' onClick={toggleDrawer(false)}>Founder's
                        Message</Link>
                    <Link to="/history" className='mobile-item' onClick={toggleDrawer(false)}>History</Link>
                    <Link to="/modern-pythian-games" className='mobile-item' onClick={toggleDrawer(false)}>Modern
                        Pythian Games</Link>
                    <Link to="/about-us" className='mobile-item' onClick={toggleDrawer(false)}>About Us</Link>
                    <Link to="/vision-and-mission" className='mobile-item' onClick={toggleDrawer(false)}>Vision and
                        Mission</Link>
                    <Link to="/aim-and-objectives" className='mobile-item' onClick={toggleDrawer(false)}>Aim and
                        Objectives</Link>
                </Accordion.Body>
            </Accordion.Item>
            <Link to="/our-council" className='mobile-item' onClick={toggleDrawer(false)}>Our Council</Link>
            <Link to="/event" className='mobile-item' onClick={toggleDrawer(false)}>Events</Link>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Event Categories</Accordion.Header>
                <Accordion.Body className='d-flex flex-column' onClick={toggleDrawer(false)}>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Musical, Vocal & Instrumental Arts'}
                    })} className='mobile-item'>Musical, Vocal & Instrumental Arts</a>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Performing & Theatre Arts'}
                    })} className='mobile-item'>Performing Theatre Arts</a>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Visual, Heritage & Artisan Arts'}
                    })} className='mobile-item'>Visual, Heritage Artisan Arts</a>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Robotic, Virtual & Digital Arts'}
                    })} className='mobile-item'>Robotic, Virtual Digital Arts</a>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Language, Literary, Oratory Arts'}
                    })} className='mobile-item'>Language, Literary, Oratory Arts</a>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Social & Cultural Arts'}
                    })} className='mobile-item'>Social Cultural Arts</a>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Architectonics & Eco Arts'}
                    })} className='mobile-item'>Architectonics Eco Arts</a>
                    <a onClick={() => navigate("/event-categories", {
                        state: {title: 'Martial Arts And Traditional Games'}
                    })} className='mobile-item'>Martial Arts And Traditional Games</a>
                    <a href={signupUrl} onClick={() => toggleDrawer(false)}
                       className='mobile-item'>Create your own
                        event</a>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Media</Accordion.Header>
                <Accordion.Body className='d-flex flex-column'>
                    <Link to="/news" className='mobile-item' onClick={toggleDrawer(false)}>News</Link>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <Link to="/membership" className='mobile-item' onClick={toggleDrawer(false)}>Membership</Link>
        <Link to="/contact" className='mobile-item' onClick={toggleDrawer(false)}>Contact</Link>
        <Link to="/donation" className='mobile-item' onClick={toggleDrawer(false)}>Donation</Link>
    </Box>);

    return (<div className="App-header">
        <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
        </Drawer>
        {!isScrolled && <div className="app-bar">
            <div className="social-link-container container">
                <a href="https://www.facebook.com/Pythiangames1/?ref=pages_you_manage"
                   className='facebook-icon'>
                    <FaFacebookF/>
                </a>
                <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FBijendergoel"
                   className='twitter-icon'>
                    <FaXTwitter/>
                </a>
                <a href="https://www.instagram.com/modernpythiangames/?igshid=YmMyMTA2M2Y%3D"
                   className='instagram-icon'>
                    <FaInstagram/>
                </a>
                <a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHGg-Faq3M32gAAAYk05M_wD13kUDJOKUxLvDuCLgnuX8Z4uEmfwTtpdryScnM4IwiV5JtfrHGuPJXFFN6UfFaUmFTTsfaB3FfwqcKYrHY8cG8Tve55ONVx7NCoUAVL-BviRCA=&original_referer=https://pythiangames.org/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fbijender-goel-773b6b20%2F"
                   className='linkedin-icon'>
                    <FaLinkedin/>
                </a>
                <a href="https://www.youtube.com/channel/UCr5Hz1CEmW56sdTkgAJYG_A"
                   className='youtube-icon'
                   style={{marginRight: '10px'}}>
                    <FaYoutube/>
                </a>
                <div className="select-language">
                    <FaEarthAmericas/>
                    {/*Select Language*/}
                    {/*<FaCaretDown/>*/}
                    <div id="google_translate_element"></div>
                </div>
            < /div>
        </div>}
        <section className={isScrolled || isMobile ? "scrolled" : ""}>
            <div
                className={isScrolled || isMobile ? "header-section-scrolled container" : "header-section container"}>
                <div className={isScrolled ? "" : "header-section-image"}>
                    <img src={logo} style={{
                        margin: isScrolled ? '5px 0' : '0px', height: isScrolled || isMobile ? '45px' : '80px'
                    }}/>
                </div>
                {isMobile ? ('') : (<ul className="header-section-tabs">
                    <li className="nav-item">
                        <Link to="/" className={isScrolled ? "nav-link scrolled-item" : "nav-link"}
                              style={{textDecoration: 'none'}}>
                            Home
                        </Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className={isScrolled ? "nav-link scrolled-item" : "nav-link"}>About Us<FaAngleDown/></a>
                        <ul className="dropdown-menu">
                            <li><Link to='/founders-message' className="dropdown-item">Founder's Message</Link>
                            </li>
                            <li><Link to='/history' className="dropdown-item">History</Link></li>
                            <li><Link to='/modern-pythian-games' className="dropdown-item">Modern Pythian
                                Games</Link></li>
                            <li><Link to='/about-us' className="dropdown-item">About Us</Link></li>
                            <li><Link to='/vision-and-mission' className="dropdown-item">Vision and
                                Mission</Link>
                            </li>
                            <li><Link to='/aim-and-objectives' className="dropdown-item">Aim and
                                Objectives</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className={isScrolled ? "nav-link scrolled-item" : "nav-link"} to="/our-council">Our
                            Council</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={isScrolled ? "nav-link scrolled-item" : "nav-link"}
                              to="/event">Events</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className={isScrolled ? "nav-link scrolled-item" : "nav-link"}>Event
                            Categories<FaAngleDown/></a>
                        <ul className="dropdown-menu">
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Musical, Vocal & Instrumental Arts'
                                    }
                                })} className="dropdown-item">Musical, Vocal & Instrumental
                                    Arts</a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MusicalVocalAndInstrumentalArts',
                                            mainPage: 'SoloInstrumentCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Solo Instrument Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MusicalVocalAndInstrumentalArts',
                                            mainPage: 'GroupCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Group Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MusicalVocalAndInstrumentalArts',
                                            mainPage: 'SingingCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Singing Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MusicalVocalAndInstrumentalArts',
                                            mainPage: 'CompositionCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Composition Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MusicalVocalAndInstrumentalArts',
                                            mainPage: 'SpecificInstrumentCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Specific Instrument Competitions </a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MusicalVocalAndInstrumentalArts',
                                            mainPage: 'SpecialCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Special Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MusicalVocalAndInstrumentalArts',
                                            mainPage: 'VariousPrograms',
                                            type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Various Programs</a>
                                    </li>
                                    <li>
                                        <a href={signupUrl}
                                           className="dropdown-item">Create Your Own Event</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Performing & Theatre Arts'
                                    }
                                })} className="dropdown-item">Performing & Theatre Arts</a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts', mainPage: 'DanceCompetitions', type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Dance Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts', mainPage: 'PoetryCompetitions', type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Poetry Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts', mainPage: 'StandUpComedy', type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Stand Up Comedy</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts', mainPage: 'StoryTelling', type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Story Telling</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts',
                                            mainPage: 'ModellingCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Modelling Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts', mainPage: 'FashionShows', type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Fashion Shows</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts', mainPage: 'ActingCompetitions', type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Acting Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts',
                                            mainPage: 'DirectingCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Directing Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts',
                                            mainPage: 'PlaywritingCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Playwriting Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts',
                                            mainPage: 'MusicalTheatreCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Musical Theatre Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts',
                                            mainPage: 'ImprovisationCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Improvisation Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'PerformingAndTheatreArts',
                                            mainPage: 'TechnicalTheatreCompetitions',
                                            type: 'A'
                                        }
                                    })}>
                                        <a className="dropdown-item">Technical Theatre Competitions</a>
                                    </li>

                                    <li>
                                        <a href={signupUrl}
                                           className="dropdown-item">Create Your Own Event</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Visual, Heritage & Artisan Arts'
                                    }
                                })} className="dropdown-item">Visual, Heritage & Artisan Arts
                                </a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'PaintingCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Painting Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'DrawingCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Drawing Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'SculptureCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Sculpture Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'PhotographyCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Photography Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'HandmadeJewelleryCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Handmade Jewellery Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'PotteryAndCeramicsCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Pottery and Ceramics Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'TextileAndWeavingCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Textile and Weaving Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'CraftsmanshipCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Craftsmanship Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'PaperArtCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Paper Art Competitions</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'VisualHeritageArtisanArts',
                                            mainPage: 'LiveArtCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Live Art Competition</a>
                                    </li>
                                    <li>
                                        <a href={signupUrl}
                                           className="dropdown-item">Customise your own Event</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Robotic, Virtual & Digital Arts'
                                    }
                                })} className="dropdown-item">Robotic, Virtual & Digital Arts</a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'RoboticVirtualDigitalArts',
                                            mainPage: 'RobotDesignChallenge',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Robot Design Challenge</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'RoboticVirtualDigitalArts',
                                            mainPage: 'DigitalArtCompetition',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Digital Art Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'RoboticVirtualDigitalArts',
                                            mainPage: 'MusicalRoyaleBattle',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Musical Royale Battle</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'RoboticVirtualDigitalArts',
                                            mainPage: 'GameDeveloperChallenge',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Game Developer Challenge</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'RoboticVirtualDigitalArts',
                                            mainPage: 'RoboticDanceCompetition',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Robotic Dance Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'RoboticVirtualDigitalArts',
                                            mainPage: '3DModellingCompetition',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">3D Modelling Competition</a>
                                    </li>
                                    <li>
                                        <a href={signupUrl}
                                           className="dropdown-item">Customise your own Event</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Language, Literary, Oratory Arts'
                                    }
                                })} className="dropdown-item">Language, Literary, Oratory Arts</a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'SpellingBeeCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Spelling Bee Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'ForeignLanguageProficiency',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Foreign Language Proficiency</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'TranslationCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Translation Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'ShortStoryWritingCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Short Story Writing Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'PoetryWritingCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Poetry Writing Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'EssayWritingCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Essay Writing Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'DebateCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Debate Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'LanguageLiteraryOratoryArts',
                                            mainPage: 'PublicSpeakingCompetition',
                                            type: 'C'
                                        }
                                    })}>
                                        <a className="dropdown-item">Public Speaking Competition</a>
                                    </li>
                                    <li>
                                        <a href={signupUrl}
                                           className="dropdown-item">Customise your own Event</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Social & Cultural Arts'
                                    }
                                })} className="dropdown-item">Social & Cultural Arts</a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'RangoliArtContest', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Rangoli Art Contest</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'MehndiDesignCompetition', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Mehndi Design Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'CulinaryArts', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Culinary Arts</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts',
                                            mainPage: 'KnittingAndCrochetingContest',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Knitting and Crocheting Contest</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts',
                                            mainPage: 'TarotCardReadingCompetition',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Tarot Card Reading Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'HospitalityChallenge', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Hospitality Challenge</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts',
                                            mainPage: 'MediaHouseExcellenceChallenge',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Media House Excellence Challenge</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'BoardGamingTournament', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Board Gaming Tournament</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'PuzzleGamesCompetition', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Puzzle Games Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'CulturalHeritageQuizBowl', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Cultural Heritage Quiz Bowl</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts',
                                            mainPage: 'CrossCulturalExchangeFair',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Cross-Cultural Exchange Fair</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'TraditionalCostumeParade', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Traditional Costume Parade</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'AstrophotographyContest', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Astrophotography Contest</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'SocialCulturalArts', mainPage: 'AstronomyQuizBowl', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Astronomy Quiz Bowl</a>
                                    </li>
                                    <li>
                                        <a href={signupUrl}
                                           className="dropdown-item">Customise your own Event</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Architectonics & Eco Arts'
                                    }
                                })} className="dropdown-item">Architectonics & Eco Arts</a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'ArchitecturalDesignCompetition',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Architectural Design Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'SustainableArchitectureShowcase',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Sustainable Architecture Showcase</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts', mainPage: 'UrbanPlanningChallenge', type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Urban Planning Challenge</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'UpcyclingDesignChallenge',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Upcycling Design Challenge</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'GreenProductDesignCompetition',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Green Product Design Competition</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'CommunityGardenDesignContest',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Community Garden Design Contest</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'InnovativeRecyclingProjectShowcase',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Innovative Recycling Project Showcase</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'EnvironmentalEducationCampaignChallenge',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Environmental Education Campaign Challenge</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'PreservationOfMonumentsAndObjects',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Preservation of Monuments and Objects</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'ArchitectonicEcoArts',
                                            mainPage: 'PreservationOfLandscape',
                                            type: 'D'
                                        }
                                    })}>
                                        <a className="dropdown-item">Preservation of Landscape</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a onClick={() => navigate('/event-categories', {
                                    state: {
                                        title: 'Martial Arts And Traditional Games'
                                    }
                                })} className="dropdown-item">Martial Arts And Traditional Games</a>
                                <ul className="submenu-menu">
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'AdventureGames', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Adventure Games</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'AirSports', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Air Sports</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'BeachGames', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Beach Games</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'BoardGames', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Board Games</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'ESports', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">E-Sports</a></li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'MartialArts', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Martial Arts</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'MotorRacing', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Motor Racing</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames',
                                            mainPage: 'PythianProleagues',
                                            type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Pythian ProLeagues</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames',
                                            mainPage: 'TraditionalGames',
                                            type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Traditional Games</a>
                                    </li>
                                    <li onClick={() => navigate('/event-categories/sub-details', {
                                        state: {
                                            title: 'MartialArtsTraditionalGames', mainPage: 'WaterSports', type: 'B'
                                        }
                                    })}>
                                        <a className="dropdown-item">Water Sports</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown">
                                <a href={signupUrl} className="dropdown-item">Create
                                    Your Own Event</a>
                            </li>

                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a className={isScrolled ? "nav-link scrolled-item" : "nav-link"}>Media<FaAngleDown/></a>
                        <ul className="dropdown-menu">
                            <li>
                                <Link to='/news' className="dropdown-item">News</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <Link className={isScrolled ? "nav-link scrolled-item" : "nav-link"}
                              to="/membership">Membership</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={isScrolled ? "nav-link scrolled-item" : "nav-link"}
                              to="/contact">Contact</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={isScrolled ? "nav-link scrolled-item" : "nav-link"}
                              to="/donation">Donation</Link>
                    </li>
                </ul>)}
                <div>
                    {isMobile ? (<div style={{gap: '15px', display: 'flex'}}>
                        <LuMenu style={{fontSize: '30px', cursor: 'pointer'}} onClick={toggleDrawer(true)}/>
                        <CgProfile style={{fontSize: '30px', cursor: 'pointer'}}
                                   onClick={() => window.location.href = signupUrl}/>
                    </div>) : (<div className="header-section-account-register">
                        <button className={isScrolled || isMobile ? 'scrolled-btn' : ''}
                                onClick={() => window.location.href = signupUrl}>Sign
                            up
                        </button>
                        <div className={isScrolled || isMobile ? "scrolled-line" : "line"}></div>
                        <button className={isScrolled || isMobile ? 'scrolled-btn' : ''}
                                onClick={() => window.location.href = loginUrl}>Login
                        </button>
                    </div>)}
                </div>
            </div>
        </section>
    </div>)
}
export default Header;