import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import '../../../assets/css/styles.css'
import './EventCategories.css'
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import createImage from '../../../assets/images/frontend/categories-images/creat-img.png';
import categoryData from '../../../assets/event-categories.json';
import {signupUrl} from '../../../assets/utils/signup';

const EventCategories = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {state} = location;
    const [data, setData] = useState('');

    useEffect(() => {
        const categoryName = state.title;
        if (categoryName === "Musical, Vocal & Instrumental Arts") {
            setData(Object.keys(categoryData.eventCat.MusicalVocalAndInstrumentalArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'MusicalVocalAndInstrumentalArts',
                            mainPage: key,
                            type: key === 'VariousPrograms' ? 'B' : 'A'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.MusicalVocalAndInstrumentalArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.MusicalVocalAndInstrumentalArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else if (categoryName === "Performing & Theatre Arts") {
            setData(Object.keys(categoryData.eventCat.PerformingAndTheatreArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'PerformingAndTheatreArts',
                            mainPage: key,
                            type: 'A'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.PerformingAndTheatreArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.PerformingAndTheatreArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else if (categoryName === "Visual, Heritage & Artisan Arts") {
            setData(Object.keys(categoryData.eventCat.VisualHeritageArtisanArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0"
                         onClick={() => navigate('/event-categories/sub-details', {
                             state: {
                                 title: 'VisualHeritageArtisanArts',
                                 mainPage: key,
                                 type: 'C'
                             }
                         })}>
                        <figure>
                            <img src={categoryData.eventCat.VisualHeritageArtisanArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.VisualHeritageArtisanArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else if (categoryName === "Robotic, Virtual & Digital Arts") {
            setData(Object.keys(categoryData.eventCat.RoboticVirtualDigitalArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'RoboticVirtualDigitalArts',
                            mainPage: key,
                            type: 'D'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.RoboticVirtualDigitalArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.RoboticVirtualDigitalArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else if (categoryName === "Language, Literary, Oratory Arts") {
            setData(Object.keys(categoryData.eventCat.LanguageLiteraryOratoryArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'LanguageLiteraryOratoryArts',
                            mainPage: key,
                            type: 'C'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.LanguageLiteraryOratoryArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.LanguageLiteraryOratoryArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else if (categoryName === "Social & Cultural Arts") {
            setData(Object.keys(categoryData.eventCat.SocialCulturalArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'SocialCulturalArts',
                            mainPage: key,
                            type: 'D'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.SocialCulturalArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.SocialCulturalArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else if (categoryName === "Architectonics & Eco Arts") {
            setData(Object.keys(categoryData.eventCat.ArchitectonicEcoArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'ArchitectonicEcoArts',
                            mainPage: key,
                            type: 'D'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.ArchitectonicEcoArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.ArchitectonicEcoArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else if (categoryName === "Martial Arts And Traditional Games") {
            setData(Object.keys(categoryData.eventCat.MartialArtsTraditionalGames).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'MartialArtsTraditionalGames',
                            mainPage: key,
                            type: 'B'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.MartialArtsTraditionalGames[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.MartialArtsTraditionalGames[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        } else {
            setData(Object.keys(categoryData.eventCat.MusicalVocalAndInstrumentalArts).map((key) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-4">
                    <div className="catlist card mb-0" onClick={() => navigate('/event-categories/sub-details', {
                        state: {
                            title: 'MusicalVocalAndInstrumentalArts',
                            mainPage: key,
                            type: key === 'VariousPrograms' ? 'B' : 'A'
                        }
                    })}>
                        <figure>
                            <img src={categoryData.eventCat.MusicalVocalAndInstrumentalArts[key].img}/>
                        </figure>
                        <figcaption>
                            <p>{categoryData.eventCat.MusicalVocalAndInstrumentalArts[key].name}</p>
                            <a className="btn-sec">Read More</a>
                        </figcaption>
                    </div>
                </div>
            )))
        }
    }, [state]);
    return (<>
        <InnerBanner pageName={state.title}/>
        <section className="section light-bg-image1">
            <div className="container">
                <div className="row">
                    {data}
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="card owneventcard">
                            <img src={createImage}/>
                            <p className='text-center'><a href={signupUrl}>Create
                                Your Own Event</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default EventCategories