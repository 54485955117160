import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../assets/css/styles.css";
import "./Event.css";
import { useEffect, useState } from "react";
import eventsApi from "../../Apis/eventsApi";
import { useNavigate } from "react-router-dom";

const Event = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const fetchEvent = async () => {
        try {
            const response = await eventsApi('upcoming');
            // console.log("Event data", response[0])
            setData(response[0])
        } catch (e) {
            console.error('Error: ', e);
        }
    }
    const getDate = (date) => {
        let tmpDate = new Date(date);
        // console.log(date)
        return tmpDate.getDate();
    }
    const getMonth = (date) => {
        let tmpDate = new Date(date);
        const day = tmpDate.getDate(); // Get the day of the month
        const month = tmpDate.toLocaleString('default', { month: 'short' }); // Get the abbreviated month name
        const formattedDate = `${day} ${month}`;
        return formattedDate;
    }
    useEffect(() => {
        fetchEvent();
    }, []);

    return (<>
        <InnerBanner pageName="Events" />
        <section className="event">
            <div className="container">
                <div className="inner-container-small text-start mb-3 mb-md-5 ">
                    <h2 className="text-center mb-3 mb-md-5 ">{data.headingtext}</h2>
                    <h1 className="large-text text-center ">{data.headingtext}</h1>
                </div>
                <div className="row mb-4">
                    <div className="col-12 col-sm-6 col-md-4 mb-30">
                        <div>
                            <div className="event-wrapper">
                                <div
                                    className="card radius-0 text-start  border-0 card-lift card-shadow"
                                    style={{ padding: "20px" }}
                                >
                                    <div className="card-body">
                                        <div>
                                            <figure>
                                                <img
                                                    src={'https://reg.pythiangames.org/' + data.eventimage}
                                                    alt='Evnet image' />
                                            </figure>
                                            <div className="card-data ">
                                                <h6>{getDate(data.startdate)}</h6>
                                                <span>{getMonth(data.startdate)}</span>
                                            </div>
                                        </div>
                                        <div className="card-content">
                                            <p className="small">
                                                <svg
                                                    width="35"
                                                    height="35"
                                                    viewBox="0 0 21 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10.5 11.8125C9.85103 11.8125 9.21664 11.6201 8.67704 11.2595C8.13744 10.899 7.71687 10.3865 7.46852 9.78693C7.22017 9.18736 7.15519 8.52761 7.2818 7.89111C7.40841 7.25461 7.72092 6.66995 8.17981 6.21106C8.6387 5.75217 9.22336 5.43966 9.85986 5.31305C10.4964 5.18644 11.1561 5.25142 11.7557 5.49977C12.3553 5.74812 12.8677 6.16869 13.2283 6.70829C13.5888 7.24789 13.7813 7.88228 13.7813 8.53125C13.7802 9.40117 13.4342 10.2352 12.819 10.8503C12.2039 11.4654 11.3699 11.8115 10.5 11.8125ZM10.5 6.5625C10.1106 6.5625 9.72998 6.67797 9.40622 6.8943C9.08246 7.11063 8.83012 7.4181 8.68111 7.77784C8.5321 8.13759 8.49312 8.53344 8.56908 8.91534C8.64505 9.29724 8.83255 9.64803 9.10789 9.92337C9.38322 10.1987 9.73402 10.3862 10.1159 10.4622C10.4978 10.5381 10.8937 10.4991 11.2534 10.3501C11.6132 10.2011 11.9206 9.94879 12.137 9.62503C12.3533 9.30127 12.4688 8.92063 12.4688 8.53125C12.4682 8.00927 12.2606 7.50881 11.8915 7.13971C11.5224 6.77061 11.022 6.56302 10.5 6.5625Z"
                                                        fill="#0FA958"
                                                    />
                                                    <path
                                                        d="M10.5 19.6875L4.96388 13.1585C4.88696 13.0604 4.81083 12.9618 4.73551 12.8625C3.78984 11.6168 3.27897 10.0952 3.28126 8.53125C3.28126 6.61672 4.0418 4.7806 5.39558 3.42682C6.74936 2.07304 8.58548 1.3125 10.5 1.3125C12.4145 1.3125 14.2507 2.07304 15.6044 3.42682C16.9582 4.7806 17.7188 6.61672 17.7188 8.53125C17.721 10.0945 17.2104 11.6154 16.2652 12.8605L16.2645 12.8625C16.2645 12.8625 16.0676 13.1211 16.0381 13.1558L10.5 19.6875ZM5.78354 12.0717C5.78354 12.0717 5.93645 12.2738 5.97123 12.3172L10.5 17.6584L15.0347 12.3099C15.0636 12.2738 15.2171 12.0704 15.2178 12.0697C15.9903 11.052 16.4078 9.80899 16.4063 8.53125C16.4063 6.96482 15.784 5.46254 14.6764 4.3549C13.5687 3.24726 12.0664 2.625 10.5 2.625C8.93357 2.625 7.43129 3.24726 6.32366 4.3549C5.21602 5.46254 4.59376 6.96482 4.59376 8.53125C4.59238 9.80978 5.01033 11.0535 5.78354 12.0717Z"
                                                        fill="#0FA958"
                                                    />
                                                </svg>
                                                {data.landmark}<br /> {data.resaddress}, &nbsp;
                                                {data.statename}
                                            </p>
                                            <h3>{data.eventname}</h3>

                                            <a onClick={() => navigate('/event/1st-National-Cultural-Pythian-Games-2024 ', {
                                                state: {
                                                    eventid: data.eventaid,
                                                    eventname: data.eventname,
                                                }
                                            })} className="activeLink themeColor">
                                                Read More
                                                <svg
                                                    width="21"
                                                    height="21"
                                                    viewBox="0 0 21 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M0.75 10.5H20.25M20.25 10.5L15 15.75M20.25 10.5L15 5.25"
                                                        stroke="#0FA958"
                                                        strokeWidth="1.29"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
};
export default Event;
