import axiosInstance from "../../assets/utils/api";

const eventsDetails = async (eventname) => {
    try {
        const response = await axiosInstance.get(
            'api/eventdetail',
            {
                headers: {
                    'Access-Token': 'bcjksdbcdjcnjkcnwkj',
                    'Content-Type': 'application/json',
                    'eventname': eventname || '1st National Cultural Pythian Games 2024'
                }
            });
        return response.data;
    } catch
    (error) {
        throw error;
    }
}
    ;

export default eventsDetails;
