import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import '../../../assets/css/styles.css'
import '../EventCategories/EventCategories.css'
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import categoryData from '../../../assets/event-categories.json';
import {signupUrl} from '../../../assets/utils/signup';

const EventSubCategories = () => {

    const [pageTitle, setPageTitle] = useState('');
    const location = useLocation();
    const {state} = location;
    const [currentPageData, setCurrentPage] = useState('');


    function formatStr(str) {
        return str.replace(/[-\s]/g, '');
    }

    useEffect(() => {

        switch (state.type) {
            case 'A':
                setCurrentPage(categoryData.eventCat[state.title][state.mainPage].data.map(item1 => (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-3 mb-md-4 cardwithIcon">
                        <div className="mb-3 mb-md-4"
                             id='mainContainer'>
                            <div className=" card mb-0">
                                <figure className="icon-bg">
                                    <img src={item1.img} alt='image'/>
                                </figure>
                                <div className="cat-detail">
                                    <div className="basic-detail">
                                        <h4 className='fw-bold'>{item1.name}</h4>
                                        {Object.keys(item1).map((key) => (

                                            Array.isArray(item1[key]) ? (<div>
                                                <p className="text-center">{key}</p>
                                                <ul>
                                                    {item1[key].map((item2) => (<li>{item2}</li>))}
                                                </ul>
                                            </div>) : ''))}
                                    </div>
                                    <a href={signupUrl}
                                       className="action-button mt-2 mt-md-4"><span></span><i>Click to
                                        Register</i></a>
                                </div>
                            </div>
                        </div>
                    </div>)))
                break;
            case 'B':
                setCurrentPage(categoryData.eventCat[state.title][state.mainPage].data.map(item1 => (
                    <div className="col-12 mb-3 mb-md-4 cardwithIcon col-md-4 col-md-12 full-width">
                        <div className=" card mb-0">
                            <figure className="icon-bg">
                                <img src={item1.img}/>
                            </figure>
                            <div className="cat-detail">
                                <div className="basic-detail">
                                    <h4 className='fw-bold'>{item1.name}</h4>
                                    {Object.keys(item1).map((key) => (Array.isArray(item1[key]) ? (<div>
                                        <p className="text-center">{key}</p>
                                        <ul>
                                            {item1[key].map((item2) => (<li>{item2}</li>))}
                                        </ul>
                                    </div>) : ''))}
                                    <div>
                                        <p className="head"> Description < /p>
                                        <ul className="description">
                                            <li>
                                                {item1.Description}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <a href={signupUrl}
                                   className="action-button mt-2 mt-md-4"><span></span><i>Click to Register</i></a>
                            </div>
                        </div>
                    </div>)))
                break;
            case
            'C':
                setCurrentPage(<div className="col-12 mb-3 mb-md-4 cardwithIcon col-md-4 col-md-12 full-width">
                    <div className=" card mb-0">
                        <figure className="icon-bg">
                            <img src={categoryData.eventCat[state.title][state.mainPage].img}/>
                        </figure>
                        <div className="cat-detail">
                            <div className="basic-detail">
                                <h4 className='fw-bold'>{categoryData.eventCat[state.title][state.mainPage].name}</h4>
                                {Object.keys(categoryData.eventCat[state.title][state.mainPage]).map(key => (Array.isArray(categoryData.eventCat[state.title][state.mainPage][key]) ? (
                                    <div>
                                        <p>{key}</p>
                                        <ul>
                                            {categoryData.eventCat[state.title][state.mainPage][key].map((item2) => (
                                                <li>{item2}</li>))}
                                        </ul>
                                    </div>) : ''))}
                            </div>
                            <a href={signupUrl}
                               className="action-button mt-2 mt-md-4"><span></span><i>Click to Register</i></a>
                        </div>
                    </div>
                </div>)
                break;
            case 'D':
                setCurrentPage(
                    <div className="col-12 mb-3 mb-md-4 cardwithIcon col-md-4 col-md-12 full-width">
                        <div className=" card mb-0">
                            <figure className="icon-bg">
                                <img src={categoryData.eventCat[state.title][state.mainPage].img}/>
                            </figure>
                            <div className="cat-detail">
                                <div className="basic-detail">
                                    <h4 className='fw-bold'>{categoryData.eventCat[state.title][state.mainPage].name}</h4>
                                    {Object.keys(categoryData.eventCat[state.title][state.mainPage]).map(key => (Array.isArray(categoryData.eventCat[state.title][state.mainPage][key]) ? (
                                        <div>
                                            <p>{key}</p>
                                            <ul>
                                                {categoryData.eventCat[state.title][state.mainPage][key].map((item2) => (
                                                    <li>{item2}</li>))}
                                            </ul>
                                        </div>) : ''))}
                                    <div>
                                        <p className="head"> Description < /p>
                                        <ul className="description">
                                            <li>
                                                {categoryData.eventCat[state.title][state.mainPage].Description}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <a href={signupUrl}
                                   className="action-button mt-2 mt-md-4"><span></span><i>Click to Register</i></a>
                            </div>
                        </div>
                    </div>)
                break;
        }
    }, [location, state]);

    return (<>
        <InnerBanner pageName={categoryData.eventCat[state.title][state.mainPage].name}/>
        <section className='section light-bg-image2'>
            <div className="container">
                <div className="row">
                    {currentPageData}
                </div>
            </div>
        </section>
        )
    </>)
}
export default EventSubCategories