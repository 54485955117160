import axiosInstance from "../../assets/utils/api";

const tracking = async (referrerUrl, toUrl, pageName) => {
    try {
        const response = await axiosInstance.post('api/tracking',
            {
                fromurl: referrerUrl,
                tourl: toUrl,
                pagename: pageName,
                browseagent: "brave",
                ip: "1.1.1.1",
                sessionid: "cbwkcjwekfjhf3485784y4",
                apifor: "pgapies"
            }, {
                headers: {
                    "Content-Type": "application/json", "Access-Token": "gcbnryetwdxfiklnhgdrf",
                }
            })
        return response.data;
    } catch (error) {
        throw error;
    }
};
export default tracking;
